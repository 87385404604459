<template>
  <div>
    <div class="mb-1" v-if="photoUrl === '' && !isEdit">
      <label for="photo" class="font-weight-bolder">Photo :</label>
      <input class="form-control" type="file" @change="uploadImageSuccess" />
    </div>

    <div class="text-center" v-if="photoUrl !== '' || isEdit">
      <div class="mb-1">
        <img :src="!isEdit ? photoUrl : editPhotoUrl" class="rounded shadow-sm" height="200px;" alt="" />
      </div>
      <b-button variant="danger" @click="removePhoto">
        <feather-icon icon="TrashIcon" />
      </b-button>
    </div>
  </div>
</template>
    
  <script>
import store from "@/store";
import { ref } from "@vue/composition-api";
import { BCard, BButton, BSpinner, BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BButton,
    BSpinner,
    BRow,
    BCol,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    editPhotoUrl: {
      type: String,
      default: '',
    }
  },
  setup(props, { emit }) {
    const photoId = ref("");
    const photoUrl = ref("");

    const uploadImageSuccess = (e) => {
      emit("input", e.target.files[0]);
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e) => {
        photoUrl.value = e.target.result;
      };
      const file = e.target.files[0];
      const formData = new FormData();
      formData.set("photos", file);
      store
        .dispatch("app-mypages/postDatas", { path: "photo", payload: formData })
        .then((response) => {
          photoId.value = response.data.data.id;
          emit("getPhotoId", photoId.value);
        });
    };

    const removePhoto = () => {
      store
        .dispatch("app-mypages/delData", {
          path: "photo",
          id: photoId.value,
        })
        .then((response) => {
          photoId.value = "";
          photoUrl.value = "";
        });
    };

    return {
      uploadImageSuccess,
      removePhoto,
      photoUrl,
      photoId,
    };
  },
};
</script>
    