<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div>
    <b-card no-body class="mb-0">
      <!-- Loading -->
      <template v-if="spinner === true">
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <div class="text-center m-5">
              <b-spinner variant="primary" label="Text Centered"></b-spinner>
            </div>
          </div>
        </div>
      </template>

      <b-card v-else no-body class="mb-0 card-company-table">
        <!-- Search -->
        <div class="m-2">
          <b-row>
            <b-col cols="12" md="12">
              <div class="d-flex align-items-center justify-content-end">
                <b-button variant="primary" v-b-modal.modal-centered>
                  <span class="text-nowrap">Add New Category</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <!-- Services Table -->
        <Table
          :items="categories.data"
          :fields="fields"
          :isCategories="true"
          @editFunc="editCategoryData"
          @delFunc="delCategoryData"
        />
      </b-card>
    </b-card>

    <!--Model-->
    <div>
      <b-modal
        v-model="myModal"
        id="modal-centered"
        centered
        size="lg"
        header-class="text-uppercase"
        scrollable
        :title="isEdit ? '' : ''"
      >
        <template #modal-header>
          <!-- Emulate built in modal header close button action -->
          <div
            class="
              w-100
              d-flex
              justify-content-between
              align-items-center
              text-uppercase
            "
          >
            <h5 class="mb-0" v-if="!isEdit">Add New Category</h5>
            <h5 class="mb-0" v-if="isEdit">Edit Category</h5>
            <b-button variant="outline-danger" @click="close"> X </b-button>
          </div>
        </template>

        <form ref="form">
          <b-form-group>
            <!-- Title -->
            <b-row class="mb-2">
              <b-col>
                <div>
                  <label for="title" class="font-weight-bolder">Name :</label>
                  <b-form-input
                    v-model="postForm.title"
                    id="title"
                    placeholder="Enter Category Title"
                    required
                  >
                  </b-form-input>
                </div>
              </b-col>
            </b-row>

            <!-- status & parent_id -->
            <b-row class="mb-2">
              <b-col>
                <div>
                  <label for="status" class="font-weight-bolder"
                    >Active :</label
                  >
                  <b-form-checkbox
                    v-model="checked"
                    @change="toggle(checked)"
                    name="check-button"
                    switch
                  >
                  </b-form-checkbox>
                </div>
              </b-col>

              <!-- <b-col>
                <div>
                  <label for="title" class="font-weight-bolder"
                    >Parent Id :</label
                  >
                  <b-form-select v-model="postForm.parent_id">
                    <b-form-select-option disabled value=""
                      >Please Select Parent Category</b-form-select-option
                    >
                    <b-form-select-option
                      v-for="c in categories"
                      :key="c.id"
                      :value="c.id"
                      >{{ c.name }}</b-form-select-option
                    >
                  </b-form-select>
                </div>
              </b-col> -->
            </b-row>

            <!-- cagtegory_type -->
            <!-- <div class="mb-2">
              <div>
                <label for="title" class="font-weight-bolder"
                  >Category Type :</label
                >
                <b-form-select v-model="postForm.category_type">
                  <b-form-select-option disabled value=""
                    >Please Select Category Type</b-form-select-option
                  >
                  <b-form-select-option
                    v-for="(c_t, index) in category_types"
                    :key="index"
                    :value="c_t"
                    >{{ c_t }}</b-form-select-option
                  >
                </b-form-select>
              </div>
            </div> -->
            <!-- Photo -->
            <!-- <div>
              <MyPhotoSingleUpload
                @getPhotoId="uploadPhoto"
                :isEdit="isEdit"
                :editPhotoUrl="photoUrl"
              />
            </div> -->
          </b-form-group>
        </form>

        <template #modal-footer>
          <div class="w-100 text-center" v-if="!isEdit">
            <b-button variant="primary" @click="handleSubmit" class="col-6">
              <b-spinner
                variant="white"
                v-if="spinner === true"
                label="Text Centered"
              ></b-spinner>
              <span v-else>Submit</span>
            </b-button>
          </div>

          <div class="w-100 text-center" v-if="isEdit">
            <b-button
              variant="primary"
              @click="updateCategoryData(postForm.id)"
              class="col-6"
            >
              <b-spinner
                variant="white"
                v-if="spinner === true"
                label="Text Centered"
              ></b-spinner>
              <span v-else>Update</span>
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>
    <!--Model-->
  </div>
</template>

<script>
import store from "@/store";
import Table from "../components/Table.vue";
import MyPhotoSingleUpload from "../components/MyPhotoSingleUpload.vue";
import { ref, reactive } from "@vue/composition-api";
import {
  BFormInput,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BCard,
  BButton,
  BSpinner,
  BRow,
  BCol,
  BModal,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  components: {
    BFormInput,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BModal,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BFormCheckbox,
    Table,
    MyPhotoSingleUpload,
  },
  emits: ["input"],
  setup(props, { emit }) {
    const fields = [
      { key: "id", label: "#" },
      { key: "title", label: "title" },
      { key: "active", label: "active" },
      { key: "actions", label: "actions" },
    ];
    const spinner = ref(false);
    const myModal = ref(false);
    const isEdit = ref(false);
    const categories = ref([]);
    const category_types = ref(["service", "event", "blog", "notification"]);
    const checked = ref(true);
    const photoUrl = ref("");
    const postForm = reactive({
      id: "",
      title: "",
      active: 1,
    });

    //Datas Fetching
    const fetchCategories = () => {
      spinner.value = true;
      store
        .dispatch("app-mypages/fetchDatas", "/our_news_categories")
        .then((response) => {
          // console.log(response.data.data.data)
          categories.value = response.data.data;
          console.log(categories.value.data)
          spinner.value = false;
        });
    };
    fetchCategories();

    //Functions
    // Photo Upload
    const uploadPhoto = (photoId) => {
      postForm.photo = `${photoId}`;
    };

    const toggle = (c) => {
      c ? (postForm.active = 1) : (postForm.active = 0);
      console.log(postForm.active);
    };

    //Data Posting
    const handleSubmit = () => {
      spinner.value = true;
      store
        .dispatch("app-mypages/postDatas", {
          path: "/our_news_categories",
          payload: postForm,
        })
        .then((response) => {
          spinner.value = false;
          postForm.title = "";
          postForm.active = 1;
          fetchCategories();
          myModal.value = false;
          isEdit.value = false;
        });
    };

    // Data Editing
    const editCategoryData = (item) => {
      myModal.value = true;
      isEdit.value = true;

      postForm.id = item.id;
      postForm.title = item.title;
      postForm.active = item.active;
    };
    const updateCategoryData = (id) => {
      spinner.value = true;
      store
        .dispatch("app-mypages/updateData", {
          path: "/our_news_categories",
          payload: postForm,
        })
        .then((response) => {
          fetchCategories();
          spinner.value = false;
          myModal.value = false;
          isEdit.value = false;
        });
    };

    // Data Deleting
    const delCategoryData = (id) => {
      spinner.value = true;
      console.log(id);
      store.dispatch("app-mypages/delData", { path: "/our_news_categories", id }).then((response) => {
        fetchCategories();
        spinner.value = false;
      });
    };

    // Close Function
    const close = () => {
      myModal.value = false;
      isEdit.value = false;

      postForm.id = "";
      postForm.title = "";
      postForm.active = 1;
    };

    return {
      fields,
      spinner,
      myModal,
      isEdit,
      categories,
      category_types,
      handleSubmit,
      photoUrl,
      uploadPhoto,
      toggle,
      postForm,
      checked,
      editCategoryData,
      updateCategoryData,
      delCategoryData,
      close,
    };
  },
};
</script>